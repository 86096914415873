<template>
  <b-card
    no-body
    class="card-revenue-budget"
    v-if="loading"
  >
    <b-card-header>
      <b-card-title>ยอดขาย รูป</b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- Updated 1 month ago -->
      </b-card-text>
    </b-card-header>

    <vue-apex-charts
      id="partner-budget-chart"
      height="450"
      type="bar"
      :options="_options"
      :series="_series"
    />
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading']),
    _series() {
      return [
        {
          data: this.results?.topicImageTotalSale?.series,
        },
      ]
    },
    _options() {
      const self = this
      return {
        chart: {
          type: 'bar',
          height: 300,
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },

        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#F30808'],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + self.withCommas(val)
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: this.results?.topicImageTotalSale?.datas,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },

        tooltip: {
          theme: 'light',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (c, x) {
                return `${x.w.globals.labels[x.dataPointIndex]} :: `
              },
            },
          },
        },
      }
    },
  },

  created() {},
}
</script>
